<template>
  <v-card class="pa-3">
    <div class="d-flex">
      <v-avatar class="ma-3" size="100" v-if="showImage">
        <v-img :src="point.image"></v-img>
      </v-avatar>
      <div class="flex-column">
        <div class="subtitle-1">
          <span class="font-weight-bold"> Ordem: </span>
          {{ Number(order) + 1 || order }}
        </div>
        <div>
          <span class="font-weight-bold"> Nome: </span> {{ point.name }}
        </div>
        <div v-if="showType">
          <span class="font-weight-bold"> Tipo: </span>
          {{ point.type | transformPointType }}
        </div>
      </div>
      <table-action-button
        v-if="showButton"
        :icon="iconButton"
        :text="tooltipButton"
        @click="$emit('click')"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    point: {
      type: Object,
      required: true,
    },
    order: {
      type: [Number, String],
      required: true,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    showType: {
      type: Boolean,
      default: true,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    iconButton: {
      type: String,
      required: () => this.showButton,
    },
    tooltipButton: {
      type: String,
      required: () => this.showButton,
    },
  },
};
</script>

<style></style>
